import React from 'react'
import ChartRadar from './ChartRadar'
import styled from 'styled-components'

const WrapComparision = styled.div`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .comparision-header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      width: 9px;
      height: 9px;
      background-color: #17B26A;
      border-radius: 50%;
    }
    h1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: #101828;
    }
  }
`

const ScoreComparision = (props) => {
  return (
    <WrapComparision>
      <div className="comparision-header">
        <span></span>
        <h1>Domain Average Score Comparison</h1>
      </div>
      <ChartRadar {...props}/>
    </WrapComparision>
  )
}

export default ScoreComparision
