import React, { useState } from 'react'
import styled from 'styled-components'

//Dropdown
import { DropdownCommon } from "@ui-partents/Dropdown";
import ScoreComparision from './ScoreComparision';
import AverageScoresByLeaner from './AverageScoresByLeaner';
import GuideDialog from './GuidedDialog/GuideDialog';

const WrapDetailPerformance = styled.div`
  padding: 20px;
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .performance-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .performance-header__title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 100%;
      h1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #101828;
      }
    }
  }
`

const options = [
  {
    label: "Clinical Reasoning Module - July 2024",
    value: "Clinical Reasoning Module - July 2024",
    text: "Clinical Reasoning Module - July 2024",
  },
  {
    label: "Cardiology Block - March 2024",
    value: "Cardiology Block - March 2024",
    text: "Cardiology Block - March 2024",
  },
  {
    label: "Neuroscience Module - October 2023",
    value: "Neuroscience Module - October 2023",
    text: "Neuroscience Module - October 2023",
  },
  {
    label: "Pharmacology Review - August 2023",
    value: "Pharmacology Review - August 2023",
    text: "Pharmacology Review - August 2023",
  },
  {
    label: "Pediatrics Rotation - June 2023",
    value: "Pediatrics Rotation - June 2023",
    text: "Pediatrics Rotation - June 2023",
  },
]

const DetailPerformance = (props) => {
  const { type } = props;
  const [dataSelect, setDataSelect] = useState('Select an assignment')

  const handleDropdownItemClick = (item) => {
    setDataSelect(item?.value);
    // Here you can add additional logic for when an item is clicked
  };

  return (
    <WrapDetailPerformance>
      <div className="performance-header">
        <div className="performance-header__title">
          <h1>Detailed Performance</h1>
          <div className="performance-filter">
            <DropdownCommon
              menuData={options}
              textDropdown={dataSelect}
              onItemClick={handleDropdownItemClick}
              type="default"
            />
          </div>
        </div>
      </div>
      <ScoreComparision {...props}/>
      {type === "cohort" && <AverageScoresByLeaner />}
      {type === "learner" && <GuideDialog />}
    </WrapDetailPerformance>
  )
}

export default DetailPerformance
