import React, { useState } from 'react'
import styled from 'styled-components'
import ReactDatePicker from 'react-datepicker';
import Calendar from '@images/teachV2/calendar.svg'
import { Icon } from '@components/common'
import ChartStackedProgress from './ChartStackedProgress';
import ChartBasicBar from './ChartBasicBar';

const WrapDomainProgress = styled.div`
  padding: 20px;
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .progress-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .progress-header__title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      h1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #101828;
      }
      .search_date {
        padding: 10px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        .react-datepicker-wrapper {
          line-height: 20px;
          input {
            border: none;
            color: #667085;
            font-size: 14px;
            font-weight: 500;
            padding: 0;
            &:focus-visible {
              outline: none;
            }
          }
        }
      }
    }
    .progress-header__sidebar {
      display: flex;
      align-items: center;
      overflow-y: hidden;
      overflow-x: scroll;
      .progress-header__sidebar-title {
        padding: 8px 16px;
        border: 1px solid #D0D5DD;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #344054;
        white-space: nowrap;
        cursor: pointer;
        &:first-child {
          border-radius: 8px 0 0 8px;
        }
        &:last-child {
          border-radius: 0 8px 8px 0;
        }
        &.active {
          color: #0089C2;
          background: #EBF9FF;
        }
      }
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: #D6DBE3; 
        border-radius: 8px;
      }
    }
  }
`

const sidebarInit = ["All Domains", "Disease Knowledge", "Medical Science Knowledge", "Reasoning Principles", "Applied Reasoning", "Pattern Recognition", "Decision-Making Efficiency" ]

const DomainProgress = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const handleChangeDateRange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  return (
    <WrapDomainProgress>
      <div className="progress-header">
        <div className="progress-header__title">
          <h1>DOMAIN PROGRESS</h1>
          <div className="search_date">
            <Icon src={Calendar} />
            <ReactDatePicker
              selected={startDate}
              onChange={handleChangeDateRange}
              selectsRange
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy/MM/dd"
            />
          </div>
        </div>
        <div className="progress-header__sidebar">
          {sidebarInit.map((item, index) => (
            <div 
              className={`progress-header__sidebar-title ${index === activeTab && 'active'}`}
              key={index}
              onClick={() => setActiveTab(index)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      {activeTab === 0 ? (
        <ChartStackedProgress />
      ) : (
        <ChartBasicBar {...props} />
      )}
    </WrapDomainProgress>
  )
}

export default DomainProgress
