import React, { useRef, useState } from 'react';
import styled, { css } from "styled-components";

//components
import IconButton from './IconButton';


//Get Token
import { authHeader } from "@helpers";

//redux
import { useSelector } from "react-redux";

//axios
import axios from 'axios';

const PatientLabel = styled.div`
    color: #5ecdfb;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
`;

const ImageBox = styled.div`
  margin-top: 60px;
  background: #6fa9e9;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;

const ImageLabelWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const BlinkingDiv = styled.div`
  position: relative;
  ${({ audioStarted }) =>
    audioStarted &&
    css`
      &:before,
      &:after {
        content: "";
        width: 120px;
        height: 120px;
        border: 9px solid rgba(20, 91, 173, 0.8);
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        @media (max-width: 768px) {
          width: 100px;
          height: 100px;
        }
      }
      &:before {
        animation: hWnWDR 0.75s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
      }
      &:after {
        animation: hWnWDR 0.75s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
        animation-delay: 0.375s;
      }

      @keyframes hWnWDR {
        from {
          transform: translate(-50%, -50%) scale(1);
          opacity: 0.8;
        }
        to {
          transform: translate(-50%, -50%) scale(1.2);
          opacity: 0.4;
        }
      }
    `}
`;

const FrameChatFullBox = styled.div`
  height: calc(100vh - 80px);
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
`;

export default function WebSocketAudioHandler({ CaseId, photoPatient, patientNameText }) {
  const [isRecording, setIsRecording] = useState(false);
  const websocketRef = useRef(null);
  const audioContextRef = useRef(null);
  const processorRef = useRef(null);
  const inputRef = useRef(null);
  const globalStreamRef = useRef(null);
  const audioQueueRef = useRef([]);
  const isPlayingRef = useRef(false);

  //get timepoint to reducer
  const caseData = useSelector((state) => state.cases || []);
  let dataResponse = caseData?.data;
  let CurrentTimePointId = dataResponse?.Case?.Timeline?.CurrentTimePointId;

  //Get Token
  const getToken = () => {
    const header = authHeader(); // Gọi hàm authHeader để lấy object
    if (!header || !header.Authorization) {
      console.error("Authorization header is missing");
      return null; // Trả về null nếu không có header
    }

    const parts = header.Authorization.split(" ");
    if (parts.length !== 2 || parts[0] !== "Token") {
      console.error("Authorization header is not in the expected format");
      return null; // Trả về null nếu không đúng định dạng
    }

    return parts[1]; // Trả về token nếu đúng định dạng
  };

  //state
  const [audioStarted, setAudioStarted] = useState(false);

  //check Domain
  const checkDomain = () => {
    const url = window.location.hostname; // Lấy domain hiện tại
    if (url.includes("app.med2lab.com")) return "pro";
    if (url.includes("app-qa.med2lab.com")) return "qa";
    if (url.includes("dev-us.med2lab.com")) return "dev";
    return "dev"; // Trường hợp không khớp với bất kỳ môi trường nào
  }

  //initialization
  const initWebSocket = async () => {
    try {
      const tokenUser = getToken();
      if (!tokenUser) {
        console.error("Token is missing or invalid");
        return;
      }

      if (!CaseId) {
        console.error("CaseId is missing");
        return;
      }

      // Gọi hàm để lấy môi trường hiện tại
      const currentEnv = checkDomain();
      
      // Call API to get WebSocket connection data
      const apiURL = `https://voice-chat-socket.med2lab.com/api/init-websocket?token=${tokenUser}&case_id=${CaseId}&timepoint_id=${CurrentTimePointId}&env=${currentEnv}`;
      const response = await axios.get(apiURL);

      if (!response.data) {
        console.error("Invalid response from API: missing connection_data");
        return;
      }

      // Close existing WebSocket connection if open
      if (websocketRef.current && websocketRef.current.readyState !== WebSocket.CLOSED) {
        websocketRef.current.close();
        console.log("Existing WebSocket connection closed before opening a new one");
      }

      // Construct WebSocket URL with response data
      const websocketURL = `wss://voice-chat-socket.med2lab.com/chat-stream?connection_data=${response.data}`;

      // Create WebSocket connection
      websocketRef.current = new WebSocket(websocketURL);
      websocketRef.current.binaryType = "arraybuffer";

      websocketRef.current.onopen = () => {
        console.log("WebSocket connection opened");
      };

      websocketRef.current.onmessage = (event) => {
        if (typeof event.data !== "string") {
          audioQueueRef.current.push(event.data);
          if (!isPlayingRef.current) {
            playAudioQueue();
          }
        }
      };

      websocketRef.current.onclose = (event) => {
        console.warn("WebSocket connection closed:", event);
      };

      websocketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
    } catch (error) {
      console.error("Failed to initialize WebSocket:", error);
    }
  };

  const initAudio = async () => {
    try {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      if (!AudioContext) throw new Error("AudioContext not supported");

      audioContextRef.current = new AudioContext();

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
        video: false,
      });

      globalStreamRef.current = stream;
      inputRef.current = audioContextRef.current.createMediaStreamSource(stream);

      processorRef.current = audioContextRef.current.createScriptProcessor(4096, 1, 1);
      processorRef.current.onaudioprocess = (e) => {
        const inputData = e.inputBuffer.getChannelData(0);
        const downsampledBuffer = downsampleBuffer(inputData, audioContextRef.current.sampleRate, 16000);
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN && downsampledBuffer) {
          websocketRef.current.send(downsampledBuffer);
        }
      };

      inputRef.current.connect(processorRef.current);
      processorRef.current.connect(audioContextRef.current.destination);
    } catch (error) {
      console.error("Error initializing audio: ", error);
      stopRecording();
    }
  };

  const downsampleBuffer = (buffer, sampleRate, outSampleRate) => {
    if (outSampleRate >= sampleRate) {
      console.warn("Downsampling rate must be lower than original sample rate");
      return null;
    }
    const sampleRateRatio = sampleRate / outSampleRate;
    const newLength = Math.round(buffer.length / sampleRateRatio);
    const result = new Int16Array(newLength);
    let offsetResult = 0;
    let offsetBuffer = 0;

    while (offsetResult < result.length) {
      const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
      let accum = 0,
        count = 0;
      for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
        accum += buffer[i];
        count++;
      }
      result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
    }

    return result.buffer;
  };

  const playAudioQueue = async () => {
    if (isPlayingRef.current) return;
    isPlayingRef.current = true;
    setAudioStarted(true);
    try {
      while (audioQueueRef.current.length > 0) {
        const arrayBuffer = audioQueueRef.current.shift();
        const audioBuffer = new Int16Array(arrayBuffer);
        const float32Buffer = new Float32Array(audioBuffer.length);

        for (let i = 0; i < audioBuffer.length; i++) {
          float32Buffer[i] = audioBuffer[i] / 0x7fff;
        }

        const buffer = audioContextRef.current.createBuffer(1, float32Buffer.length, 24000);
        buffer.getChannelData(0).set(float32Buffer);

        const source = audioContextRef.current.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContextRef.current.destination);

        await new Promise((resolve) => {
          source.onended = resolve;
          source.start();
        });
      }
    } catch (error) {
      console.error("Error during audio playback:", error);
    } finally {
      isPlayingRef.current = false;
      setAudioStarted(false);
    }
  };

  const startRecording = () => {
    if (isRecording) return;
    setIsRecording(true);
    initWebSocket();
    initAudio();
  };

  const stopRecording = () => {
    if (!isRecording) return;
    setIsRecording(false);

    if (processorRef.current) processorRef.current.disconnect();
    if (inputRef.current) inputRef.current.disconnect();
    if (audioContextRef.current && audioContextRef.current.state !== "closed") {
      audioContextRef.current.close();
    }

    if (websocketRef.current) {
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.close();
      }
      websocketRef.current = null;
    }

    if (globalStreamRef.current) {
      globalStreamRef.current.getTracks().forEach((track) => track.stop());
    }

    audioQueueRef.current = [];
    isPlayingRef.current = false;
    setAudioStarted(false);
  };

  return (
    <FrameChatFullBox className="frameChat__fullBox" data-type="patient-streaming-voice">
      <div className="h-100 w-100 text-center d-flex flex-column justify-content-between">
        <ImageLabelWrapper>
          <BlinkingDiv audioStarted={audioStarted}>
            <ImageBox>
              <StyledImage src={photoPatient} alt="Patient" className={`img-fluid`} />
            </ImageBox>
          </BlinkingDiv>
          <PatientLabel>{patientNameText || "Patient"}</PatientLabel>
        </ImageLabelWrapper>
        <IconButton
          isRecording={isRecording}
          onClick={isRecording ? stopRecording : startRecording}
        />
      </div>
    </FrameChatFullBox>
  );
}