import React, { useState, useEffect } from "react";
import { Input, Label, FormGroup } from "reactstrap";
import styled from "styled-components";
import { apiCaller } from "@utils";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Container = styled.div` background-color: white; `;
const Heading = styled.h3` color: #081b2a; font-size: 18px; font-weight: 600; border-bottom: 1px solid #d0d5dd; padding-bottom: 8px; `;
const StyledLabel = styled(Label)` color: #344054; font-size: 14px; font-weight: 500; `;

const VirtualPreceptorMain = () => {
    const { id } = useParams();
    const timepointData = useSelector((state) => state?.timepoint?.data || {});

    const [interactingMethod, setInteractingMethod] = useState(timepointData?.InstructorChatMode || "text");
    const [voiceSpeed, setVoiceSpeed] = useState(Number(timepointData?.InstructorChatSpeed) || 1);
    const [timePointId, setTimePointId] = useState(timepointData?.Id || null);

    useEffect(() => {
        setInteractingMethod(timepointData?.InstructorChatMode || "text");
        setVoiceSpeed(Number(timepointData?.InstructorChatSpeed) || 1);
        setTimePointId(timepointData?.Id || null);
    }, [timepointData]);

    // Gửi InstructorChatMode và InstructorChatSpeed nếu chọn voice
    const updateInstructorChatMode = (mode) => {
        setInteractingMethod(mode);

        let params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: timePointId,
            InstructorChatMode: mode, // Gửi InstructorChatMode
        };

        if (mode === "voice") {
            params.InstructorChatSpeed = voiceSpeed; // Gửi luôn speed khi chọn voice
        }

        apiCaller(`/api/teach/case/${id}/`, "PUT", params)
            .then((res) => console.log("Mode updated:", res))
            .catch((err) => console.error("Error updating mode:", err));
    };

    // Gửi InstructorChatSpeed nếu đang ở chế độ voice
    const updateInstructorChatSpeed = (speed) => {
        if (interactingMethod === "voice") {
            setVoiceSpeed(speed);
            apiCaller(`/api/teach/case/${id}/`, "PUT", {
                Action: "Update",
                Target: "TimePoint",
                TimePointId: timePointId,
                InstructorChatSpeed: speed, // Chỉ gửi InstructorChatSpeed
            })
                .then((res) => console.log("Speed updated:", res))
                .catch((err) => console.error("Error updating speed:", err));
        }
    };

    const voiceSpeedOptions = [
        { label: "0.5", value: 0.5 },
        { label: "0.75", value: 0.75 },
        { label: "Normal", value: 1 },
        { label: "1.2", value: 1.2 },
        { label: "1.5", value: 1.5 },
        { label: "2.0", value: 2.0 }
    ];

    return (
        <Container>
            <Heading>Virtual Preceptor Settings</Heading>
            <FormGroup tag="fieldset" className="mt-4">
                <StyledLabel>Interaction Method</StyledLabel>
                {["voice", "text"].map((mode) => (
                    <FormGroup check key={mode}>
                        <StyledLabel check>
                            <Input
                                type="radio"
                                name="interactingMethod"
                                value={mode}
                                checked={interactingMethod === mode}
                                onChange={() => updateInstructorChatMode(mode)}
                            /> {mode.charAt(0).toUpperCase() + mode.slice(1)}
                        </StyledLabel>
                    </FormGroup>
                ))}
            </FormGroup>

            {interactingMethod === "voice" && (
                <FormGroup className="mt-4">
                    <StyledLabel>Voice Speed</StyledLabel>
                    <Input
                        type="select"
                        value={voiceSpeed}
                        onChange={(e) => updateInstructorChatSpeed(Number(e.target.value))}
                    >
                        {voiceSpeedOptions.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </Input>
                </FormGroup>
            )}
        </Container>
    );
};

export default VirtualPreceptorMain;