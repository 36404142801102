import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

//redux
import { useDispatch } from "react-redux";

// components
import WhisperSTTComponent from './WhisperSTTComponent';
import TextToSpeech from './TextToSpeech';
import MessageBoxEndChat from './MessageBoxEndChat';
import ButtonNextQuestion from './ButtonNextQuestion';
import SpeedSelector from './SpeedSelector';

// Styled components
import {
    StyledImage,
    PatientLabel,
    ImageLabelWrapper,
    ResponseText,
    BlinkingDiv
} from './InstructorVoiceChat.styles';

//constants
import { questionConstants } from "@constants";

//actions
import { atcCallLockElementsChat } from "@actions";

//react-router-dom
import { useParams } from "react-router-dom";

//apiCaller
import { apiCaller } from "@utils";

//imgPerson
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";

function InstructorVoiceChat(props) {

    let { id } = useParams();
    const dispatch = useDispatch();
    const { isShowForSwitch, ChatMode, isCurrentTask, handleFetchInstruction } = props && props;

    // Redux data
    const caseData = useSelector((state) => state?.cases || []);
    const caseResponse = caseData?.data?.Case;

    //Question Reducer
    const questionData = useSelector((state) => state.question || {});
    let QuestionResponse = questionData?.data?.Question;
    let TaskToResume = questionData?.data?.TaskToResume || null;
    let QuestionTypeCurrent = QuestionResponse?.Type;
    let SubType = QuestionResponse?.SubType;
    const mutipleChoiceData = QuestionResponse?.AnswerChoices;

    // let isFreeText = QuestionTypeCurrent === "free_text";
    const questionId = QuestionResponse?.Id;

    //state
    const [optionsData, setOptionsData] = useState({
        SelectMany: mutipleChoiceData || null,
        SelectOne: null
    });
    const [audioFinished, setAudioFinished] = useState(false);
    const [audioStarted, setAudioStarted] = useState(false);
    const [statusGenerateAudio, setStatusGenerateAudio] = useState('idle');
    const [nextQuestion, setNextQuestion] = useState(false);
    const [textTranscript, setTextTranscript] = useState(null);
    const [textResponseTmp, setTextResponseTmp] = useState('');
    const [isLoadingTTS, setLoadingTTS] = useState(false);
    const [finalQuestion, setFinalQuestion] = useState(false);
    const [selectedSpeed, setSelectedSpeed] = useState(1.0);

    //Nếu có question Next trả về từ StandardScreenMain
    useEffect(() => {
        const QuestionResponse = questionData?.data?.Question;
        if (QuestionResponse) {
            processQuestionResponse(QuestionResponse);
        }
    }, [questionData?.data?.Question]);

    // Chat Voice Condition
    const showSwitchVoiceText = isShowForSwitch ? "d-block" : "d-none";
    const chatModeClass = ChatMode === "switch_voice_text" ? showSwitchVoiceText : "";

    //Check Hien thi I got it
    useEffect(() => {
        //Kết thúc audio mới hiển thị Button I got it
        if (finalQuestion && statusGenerateAudio === "ended") {
            setNextQuestion(true);
            setFinalQuestion(false); //Reset lại trạng thái finalQuestion
        }
    }, [finalQuestion, statusGenerateAudio]);

    const handleAudioStart = () => {
        setAudioStarted(true);
        console.log("Audio has started playing");
    };

    const handleAudioEnd = () => {
        setAudioFinished(true);
        setAudioStarted(false);
        setTextResponseTmp(null);
    };

    const handleStatusChange = (newStatus) => {
        setStatusGenerateAudio(newStatus);
        // Trường hợp hiển thị button I got it phải chờ api phát audio xong.
        if (newStatus === 'playing') {
            setTextTranscript(textResponseTmp);

            //Mục đích giữ trạng thái processing đến khi phát âm thanh
            setLoadingTTS(false);
        }
    };

    //Xử lý view dựa vào QuestionResponse
    const processQuestionResponse = (QuestionResponse) => {
        if (!QuestionResponse) return;
        let newTextResponse = QuestionResponse.Explanation || QuestionResponse.Prompt || "";
        setTextResponseTmp(newTextResponse);
        if (QuestionResponse.Explanation) {
            setFinalQuestion(true);
        }
    };

    //Call api Next Question (Khi Click Got It)
    const handleNextQuestion = () => {
        setLoadingTTS(true);
        dispatch({ type: questionConstants.QUESTION_REQUEST, isLoading: true });

        // Trước khi gọi API, khóa view
        dispatch(atcCallLockElementsChat(true));
        apiCaller(`/api/learn/question/next/${id}/?task=${isCurrentTask}`, "GET", null).then((res) => {
            if (res.status === 200) {
                const data = res?.data;
                const QuestionResponse = data?.Question;
                const resQuestionNull = data?.Question === null;

                //Check Data
                processQuestionResponse(QuestionResponse);

                //Save Question Response
                dispatch({
                    type: questionConstants.QUESTION_SUCCESS,
                    payload: data,
                    isLoading: false
                });

                // Neu Question Null thi kiem Tra Instruction co khong ?
                // => Neu Co Instruction Thi Show Toast tuong ung vs Task hien tai
                if (resQuestionNull && isCurrentTask) {
                    handleFetchInstruction(id, isCurrentTask);
                }

                //Hidden Loading
                setNextQuestion(false); //Da Nhan nut Next Question

                // Sau khi xử lý API, mở khóa view
                dispatch(atcCallLockElementsChat(false));
            } else {
                //Hidden Loading
                dispatch({ type: questionConstants.QUESTION_FAILURE, error: "error", isLoading: false });

                // Sau khi xử lý API, mở khóa view
                dispatch(atcCallLockElementsChat(false));
            }
        });
    };

    // Clear Data
    function clearMultipleChoiceData(optionsData, SubType, setOptionsData) {
        const newData = { ...optionsData };
        if (SubType === "select_many") {
            newData.SelectMany = null;
        } else if (SubType === "select_one") {
            newData.SelectOne = null;
        }
        setOptionsData(newData);
    }

    // Handle Submit Question
    const handleSubmitQuestion = (AnswerInput = null) => {
        console.log(AnswerInput, "AnswerInput........");
        setLoadingTTS(true);
        // Trả về một promise
        return new Promise(async (resolve, reject) => {
            try {
                // Trước khi gọi API, khóa view
                dispatch(atcCallLockElementsChat(true));

                // Check Question type
                let paramsAnswer;
                switch (QuestionTypeCurrent) {
                    // case "multiple_choice":
                    //     paramsAnswer = SubType === "select_many" ? optionsData?.SelectMany : optionsData?.SelectOne;
                    //     break;
                    case "free_text":
                        paramsAnswer = AnswerInput;
                        break;
                    default:
                        paramsAnswer = "";
                        break;
                }

                // Params
                const params = {
                    "CaseId": id,
                    "QuestionId": questionId,
                    "QuestionType": QuestionTypeCurrent,
                    "Answer": paramsAnswer
                };

                // Gọi API
                const res = await apiCaller(`/api/learn/question/submit/`, "POST", params);

                if (res?.status === 200) {
                    const data = res?.data;
                    const QuestionResponse = data?.QuestionResponse;
                    // Update Reducer
                    dispatch({
                        type: questionConstants.QUESTION_SUBMIT,
                        payload: data,
                    });

                    // Check QuestionResponse xem trả về Explanation hay Prompt
                    processQuestionResponse(QuestionResponse);

                    // Sau khi xử lý API, mở khóa view
                    dispatch(atcCallLockElementsChat(false));

                    // Nếu null thì check còn question nào khác không ?
                    if (QuestionResponse === null) {
                        handleNextQuestion();
                    }

                    // Clear selected data for multiple choice
                    if (QuestionTypeCurrent === "multiple_choice") {
                        clearMultipleChoiceData(optionsData, SubType, setOptionsData);
                    }

                    // Resolve kết quả
                    resolve(data);
                } else {
                    dispatch({ type: questionConstants.QUESTION_FAILURE });
                    dispatch(atcCallLockElementsChat(false)); // Mở khóa view nếu có lỗi
                    reject(new Error("Failed to submit question"));
                    return;
                }
            } catch (error) {
                reject(error);
            } finally {
                // Mở khóa view trong trường hợp lỗi
                dispatch(atcCallLockElementsChat(false));
            }
        });
    };

    // Characters
    const Characters = caseResponse?.Characters;
    const Instructor = Characters?.Instructor;
    const InstructorCharacter = Instructor?.Character;
    const preceptorNameText = Instructor?.DisplayName;
    const PreceptorAvatar = InstructorCharacter?.StandardSquarePhoto || imageInstructor;
    console.log(selectedSpeed, "selectedSpeed...");
    return (
        <div className={`frameChat__fullBox position-relative ${chatModeClass}`} data-type="instructor-voice">
            <ImageLabelWrapper>
                <BlinkingDiv audioStarted={audioStarted}>
                    <StyledImage
                        src={PreceptorAvatar}
                        alt={preceptorNameText}
                        className={`img-fluid`}
                    />
                </BlinkingDiv>
                <PatientLabel>{preceptorNameText || "Instructor"}</PatientLabel>
                {textTranscript &&
                    <ResponseText className="scrollbarStyle-small" dangerouslySetInnerHTML={{ __html: textTranscript }} />
                }
            </ImageLabelWrapper>

            {/* Chỉ hiển thị TextToSpeech khi không có nextQuestion hoặc TaskToResume */}
            {(!TaskToResume && nextQuestion === false) && (
                <WhisperSTTComponent
                    {...props}
                    audioFinished={audioFinished}
                    audioStarted={audioStarted}
                    statusGenerateAudio={statusGenerateAudio}
                    handleSubmitVoice={handleSubmitQuestion}
                    contentSpeechAudio={textResponseTmp}
                    isLoadingTTS={isLoadingTTS}
                />
            )}

            <TextToSpeech
                {...props}
                onAudioStart={handleAudioStart}
                onAudioEnd={handleAudioEnd}
                onStatusChange={handleStatusChange}
                contentSpeechAudio={textResponseTmp}
                statusGenerateAudio={statusGenerateAudio}
                selectedSpeed={selectedSpeed}
            />

            {/* Apply fadeInUp animation to MessageBoxEndChat and TaskToResume */}
            {(TaskToResume || nextQuestion) && (
                <React.Fragment>
                    {TaskToResume ? (
                        <MessageBoxEndChat {...props} TaskToResume={TaskToResume} />
                    ) : (
                        <ButtonNextQuestion
                            {...props}
                            handleNextQuestion={handleNextQuestion}
                            isLoading={questionData?.isLoading?.nextQuestion}
                            statusAudio={statusGenerateAudio === "playing"}
                        />
                    )}
                </React.Fragment>
            )}
            <SpeedSelector 
                {...props}
                setSelectedSpeed={setSelectedSpeed}
                selectedSpeed={selectedSpeed}
            />
        </div>
    );
}

export default InstructorVoiceChat;