import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { PulseButtonAnimate } from '../../EngageApp/ChatVoice';

//common
import { useWhisperSTT } from "@components-common/Voice";

//utils
import { apiCaller } from "@utils";

//react-router-dom
import { useLocation } from 'react-router-dom';

const WhisperSTTComponent = (props) => {
    const { handleContentForSpeech, resChatData, handleResetContentFirst, onStatusChange } = props && props;
    const { isRecording, startRecording, stopRecording } = useWhisperSTT(resChatData);

    const [permissionGranted, setPermissionGranted] = useState(false); // State to track if the user has granted microphone access
    const [error, setError] = useState(null); // State to manage errors
    const mainRef = useRef(null);
    const audioVisualizerRef = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const checkVersion = queryParams.get('check-version');

    // This function is called when the transcription process is completed
    const handleTranscriptionFinish = (text) => {
        if (text) {
            onStatusChange("streaming")
            const params = {
                "action_type": "engage_conversation",
                "engage_id": resChatData?.engage_id,
                "model": "gpt-3.5-turbo",
                "data_chat": text
            };

            // Direct API call without handling stream
            apiCaller(`/api/engage/functional/`, 'POST', params).then(res => {
                let data = res?.data;
                if (res?.status === 200) {
                    handleContentForSpeech(data);
                } else {
                    console.log("error");
                }
            });
        }
    };

    // Request microphone access
    const requestPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            stream.getTracks().forEach(track => track.stop());
            setPermissionGranted(true);
            setError(null); // Reset error if access has been granted
        } catch (error) {
            console.error('Error accessing the microphone:', error);
            setPermissionGranted(false);
            setError('Microphone access denied or unavailable. Please enable the microphone and try again.');
        }
    };

    // Handle button click
    const handleButtonClick = async () => {
        handleResetContentFirst();
        if (!permissionGranted) {
            await requestPermission();
        }
        if (permissionGranted) {
            if (isRecording) {
                const transcribedText = await stopRecording(); // NOW receives transcribed text
                handleTranscriptionFinish(transcribedText); // Pass the text to the parent function
                if (audioVisualizerRef.current) {
                    audioVisualizerRef.current.stop();
                }
            } else {
                startRecording();
                initAudioVisualizer();
            }
        }
    };
    
    useEffect(() => {
        if(checkVersion === 'true') {
            navigator.sayswho= (function(){
                var ua= navigator.userAgent;
                var tem; 
                var M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
                if(/trident/i.test(M[1])){
                    tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
                    return 'IE '+(tem[1] || '');
                }
                if(M[1]=== 'Chrome'){
                    tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
                    if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
                }
                M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
                if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
                return M.join(' ');
            })();
            console.log(checkVersion, "checkVersion.....");
            alert(navigator.sayswho);
        }
        
        requestPermission();
    }, [checkVersion]);

    // Initialize the audio visualizer
    const initAudioVisualizer = () => {
        const visualMainElement = mainRef.current;
        const visualValueCount = 16;
        let visualElements;

        const createDOMElements = () => {
            for (let i = 0; i < visualValueCount; ++i) {
                const elm = document.createElement('div');
                elm.style.background = `linear-gradient(90deg, #2268F0 ${(i / visualValueCount) * 100}%, #003EFF 100%)`;
                visualMainElement.appendChild(elm);
            }
            visualElements = visualMainElement.querySelectorAll('div');
        };

        const init = () => {
            const audioContext = new AudioContext();
            visualMainElement.innerHTML = '';
            createDOMElements();

            const dataMap = { 0: 15, 1: 10, 2: 8, 3: 9, 4: 6, 5: 5, 6: 2, 7: 1, 8: 0, 9: 4, 10: 3, 11: 7, 12: 11, 13: 12, 14: 13, 15: 14 };
            const processFrame = (data) => {
                const values = Object.values(data);
                for (let i = 0; i < visualValueCount; ++i) {
                    const value = values[dataMap[i]] / 255;
                    const elmStyles = visualElements[i].style;
                    elmStyles.transform = `scaleY(${value * 1.3})`; // Tăng cường độ sóng âm bằng cách nhân với 2
                    elmStyles.opacity = Math.max(.25, value);
                }
            };

            audioVisualizerRef.current = new AudioVisualizer(audioContext, processFrame);
        };

        init();
    };

    return (
        <WrapperDiv>
            <StyledMain ref={mainRef} className={`${isRecording ? 'd-block' : 'd-none'}`}></StyledMain>
            <PulseButtonAnimate
                {...props}
                onButtonClick={handleButtonClick}
                disabled={!permissionGranted}
                isRecording={isRecording}
            />
            <Text>{isRecording && 'Listening... Press to Stop'}</Text>
            {error && <Alert>{error}</Alert>} {/* Display error message */}
        </WrapperDiv>
    );
};

class AudioVisualizer {
    constructor(audioContext, processFrame) {
        this.audioContext = audioContext;
        this.processFrame = processFrame;
        this.connectStream = this.connectStream.bind(this);
        this.rafId = null;
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(this.connectStream)
            .catch((error) => {
                console.error('Error accessing the microphone:', error);
            });
    }

    connectStream(stream) {
        this.analyser = this.audioContext.createAnalyser();
        const source = this.audioContext.createMediaStreamSource(stream);
        source.connect(this.analyser);
        this.analyser.smoothingTimeConstant = 0.5;
        this.analyser.fftSize = 32;
        this.initRenderLoop();
    }

    initRenderLoop() {
        const frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
        const renderFrame = () => {
            this.analyser.getByteFrequencyData(frequencyData);
            this.processFrame(frequencyData);
            this.rafId = requestAnimationFrame(renderFrame);
        };
        this.rafId = requestAnimationFrame(renderFrame);
    }

    stop() {
        if (this.rafId) {
            cancelAnimationFrame(this.rafId);
            this.rafId = null;
        }
    }
}

const StyledMain = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: inline-block;
    width: 3px;
    height: 44px;
    margin: 0 7px;
    transform: scaleY(.5);
    opacity: .25;
  }
`;

const WrapperDiv = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 80px;
    flex-direction: column;
`;

const Text = styled.div`
  color: #081B2A;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Alert = styled.div`
  color: red;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
`;

export default WhisperSTTComponent;