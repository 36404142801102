import React from 'react'
import styled from "styled-components";
import ReactECharts from 'echarts-for-react';

const Wrapper = styled.div`
  width: 100%;
`

const ChartRadar = (props) => {

  const option = {
    color: ['#79DDFC61', '#f3a32c33'],
    legend: {
      orient: "vertical", // Đặt legend theo chiều dọc
      left: "left", // Căn trái
      top: "20%",
    },
    radar: {
      indicator: [
        { name: 'Disease Knowledge', max: 6500 },
        { name: 'Medical Science Knowledge', max: 16000 },
        { name: 'Reasoning Principles', max: 30000 },
        { name: 'Pattern Recognition', max: 38000 },
        { name: 'Decision-Making Efficiency', max: 52000 },
      ],
      axisName: {
        color: '#475467'
      },
    },
    series: [
      {
        type: 'radar',
        data: [
          {
            value: [4200, 3000, 20000, 35000, 50000],
            name: 'My Score',
            lineStyle: {
              color: "#0BA5EC", // Màu của đường line
              width: 2, // Độ dày đường line
              type: "solid", // Kiểu nét (solid, dashed, dotted)
            },
            areaStyle: {
              color: "#79DDFC61"
            },
          },
          {
            value: [1200, 9200, 30000, 25000, 5000],
            name: 'My cohort',
            lineStyle: {
              color: "rgba(243, 163, 44, 0.2)", // Màu của đường line
              width: 1, // Độ dày đường line
              type: "solid", // Kiểu nét (solid, dashed, dotted)
            },
            areaStyle: {
              color: "rgba(243, 163, 44, 0.2)"
            },
          },
        ]
      }
    ]
  };

  return (
    <Wrapper>
      <ReactECharts option={option} />
    </Wrapper>
  )
}

export default ChartRadar
