import React, { useState, useEffect, useRef, useContext } from 'react';

//Scrollbar
import { useParams } from "react-router-dom";

//apiCaller
import { apiCaller } from "@utils";

//redux
import { useDispatch, useSelector } from "react-redux";

//imgPerson
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";

//context
import { DeviceContext } from '@context/DeviceContext';

//Icon
import IconNotSupport from "@images/access-mic/not-support-voice.svg";

// Components
import { MicrophoneMessage } from "@ui-partents/Message";

//components
import {
    ScrollableMessages,
    QuestionContainer
} from '@components/learner/StandardScreen/Chat/Preceptor';

//UserActive
import { UserActive } from "../../Chat";

//lodash
import _ from "lodash";

//constants
import { questionConstants } from "@constants";

//actions
import { atcCallLockElementsChat, setTypeMicrophoneInstructor } from "@actions";

function Framechat(props) {
    //props
    const { messagesEndRef, handleUpdateRecord, isCurrentTask, handleFetchInstruction, showUserActive } = props && props;
    let { id } = useParams();
    const dispatch = useDispatch();

    //Case - Reducer
    const caseReducer = useSelector((state) => state.cases || []);
    const caseResponse = caseReducer?.data?.Case;
    let statusMessageChatVoice = useSelector((state) => state?.statusBrowserForMicrophone?.isActive);
    let FirstTimePoint = caseResponse?.Timeline?.TimePointNum === 1;

    //Question Reducer
    const questionData = useSelector((state) => state.question || {});
    let QuestionResponse = questionData?.data?.Question;
    let TaskToResume = questionData?.data?.TaskToResume || null;
    let QuestionTypeCurrent = QuestionResponse?.Type;
    let SubType = QuestionResponse?.SubType;
    const mutipleChoiceData = QuestionResponse?.AnswerChoices;
    let isFreeText = QuestionTypeCurrent === "free_text";

    //context
    const { device, deviceType } = useContext(DeviceContext);  // Get device and device type from context
    const [modalMessageIOS, setModalMessageIOS] = useState(false);

    // Ref for textarea
    const textareaRef = useRef(null);

    // Auto-focus textarea on mount
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //get height bottom of chat
    const divRef = useRef(null);
    const [heightFooterChat, setheightFooterChat] = useState(0);
    useEffect(() => {
        if (divRef.current) {
            // Sử dụng offsetHeight để lấy chiều cao của div
            const height = divRef.current.offsetHeight;
            setheightFooterChat(height);
        }
    }, [QuestionResponse]);

    //state
    const [optionsData, setOptionsData] = useState({
        SelectMany: mutipleChoiceData || null,
        SelectOne: null
    });
    const [nextQuestion, setNextQuestion] = useState(false);
    const [valueOptions, setvalueOptions] = useState(null);
    const [questionId, setQuestionId] = useState(QuestionResponse?.Id || null);
    const [isLoading, setLoading] = useState({
        submitQuestion: false,
        streamData: false
    });

    //---------stream Data-------//
    const [contentStream, setStream] = useState(null);
    const [isMessageLoader, setMessageLoader] = useState(null);
    let intervalIDRef = useRef(null);

    // State Load Question Id
    useEffect(() => {
        const QuestionResponse = questionData?.data?.Question;
        setQuestionId(QuestionResponse?.Id || null);
    }, [questionData])

    //Them Property ExplanationText cho array
    useEffect(() => {
        const QuestionResponse = questionData?.data?.Question;
        const SubType = QuestionResponse?.SubType;
        const AnswerChoices = QuestionResponse?.AnswerChoices;
        // Format Data
        const AddPropertyToArray = (data) => {
            const newData = data?.map((value) => ({
                ...value,
                ExplanationText: "",
                Selected: false,
            }));

            if (SubType === "select_many") {
                setOptionsData((prevState) => ({
                    ...prevState,
                    SelectMany: newData || [],
                }));
            } else if (SubType === "select_one") {
                setOptionsData((prevState) => ({
                    ...prevState,
                    SelectOne: newData || [],
                }));
            }
        };
        AnswerChoices?.length > 0 && AddPropertyToArray(AnswerChoices || []);
    }, [questionData]);

    // stream Data
    const handleStreamData = (questionPromise) => {
        // Neu questionPromise chua co ket qua tra ve thi sau 3s goi api nay
        Promise.race([questionPromise, new Promise(resolve => setTimeout(resolve, 3000, 'timeout'))]).then(result => {
            if (result !== 'timeout') {
                console.log('API question returned before timeout');
                return;
            }

            console.log('API question timed out. Calling API stream_data...');
            intervalIDRef.current = setInterval(() => {
                setLoading((prevState) => ({ ...prevState, streamData: true }));
                apiCaller(`/api/learn/case/stream_data/${id}/`, 'GET').then(res => {
                    const dataStream = res?.data;
                    if (res?.status === 200) {
                        setStream(dataStream);
                        setLoading((prevState) => ({ ...prevState, streamData: false }));

                        //Scroll Bottom
                        handleScrollBottom();
                    } else {
                        setStream(null);
                        setLoading((prevState) => ({ ...prevState, streamData: false }));

                        // Xoa Stream
                        clearInterval(intervalIDRef.current);
                        intervalIDRef.current = null;
                    }
                });
            }, 1000);
        });
    }

    //Scroll Bottom
    const handleScrollBottom = () => {
        messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
    }

    //Handle Change Mutiple Choice
    const handleSelectMany = (data, e) => {
        const { value, checked, name } = e.target;
        const newDataChoices = [...optionsData?.SelectMany];
        const getAttributeName = e?.target?.getAttribute('data-name');
        let index = newDataChoices.findIndex((value) => value.Id === data.Id);

        if (getAttributeName === "ExplanationText") {
            newDataChoices[index]["ExplanationText"] = value;
        }
        if (name === "Selected") {
            newDataChoices[index]["Selected"] = checked;
        }

        // Show data for input
        displaySelectedItemsInInput(newDataChoices);

        //setData
        setOptionsData((prevState) => ({ ...prevState, SelectMany: newDataChoices || [] }));
    };

    const displaySelectedItemsInInput = (newDataChoices) => {
        const selectedItems = newDataChoices.filter(item => item.Selected).map(item => item.Content).join(", ");
        setvalueOptions(selectedItems);
    }

    // Handle Select One
    const handleSelectOne = (data, e) => {
        const { value, checked, name } = e.target;
        const newDataChoices = [...optionsData?.SelectOne];

        if (name === "Selected") {
            let index = newDataChoices.findIndex((value) => value.Id === data.Id);

            // Kiểm tra xem đã có một phần tử nào có giá trị "Selected" là true chưa
            const hasTrueSelected = newDataChoices.some((choice) => choice.Selected === true);

            // Nếu đã có một phần tử nào có giá trị "Selected" là true, hãy đặt giá trị của tất cả các phần tử khác thành false
            if (hasTrueSelected) {
                newDataChoices.forEach((choice) => {
                    choice.Selected = false;
                });
            }

            // Đặt giá trị "Selected" của phần tử hiện tại thành giá trị của checked
            newDataChoices[index]["Selected"] = checked;
        }

        if (name === "ExplanationText") {
            let index = newDataChoices.findIndex((value) => value.Id === data.Id);
            newDataChoices[index]["ExplanationText"] = value;
        }

        // Show data for input
        displaySelectedItemsInInput(newDataChoices);

        //set data
        setOptionsData((prevState) => ({ ...prevState, SelectOne: newDataChoices || [] }));
    };

    // Clear Data
    function clearMultipleChoiceData(optionsData, SubType, setOptionsData) {
        const newData = { ...optionsData };
        if (SubType === "select_many") {
            newData.SelectMany = null;
        } else if (SubType === "select_one") {
            newData.SelectOne = null;
        }
        setOptionsData(newData);
    }

    // Hanlde Push Data to reducer
    const pushDataToReducerForUser = async (data, type) => {
        //Push data vao store
        const selectedItems = _.chain(data).filter(item => item.Selected).map(item => item.Content).join(", ").value();
        if (type === "multiple_choice") {
            // multiple_choice
            await handleUpdateRecord(selectedItems, type, 'User');
        } else {
            // free_text
            await handleUpdateRecord(data, type, 'User');
        }
    }

    // Handle Submit Question
    const resetLoadingAndMessage = () => {
        setLoading((prevState) => ({ ...prevState, submitQuestion: false }));
        setMessageLoader(null);
    };

    //Handle Next Question
    const handleNextQuestionIfNeeded = (QuestionResponse, currentType) => {
        console.log(QuestionResponse, "handleNextQuestionIfNeeded....");
        if (QuestionResponse === null) {
            handleNextQuestion();
        } else {
            handleUpdateRecord(QuestionResponse, currentType, 'Instructor');
        }
    };

    // Handle Submit Question
    const handleSubmitQuestion = (AnswerInput = null) => {
        // Trả về một promise
        return new Promise(async (resolve, reject) => {
            try {
                // Trước khi gọi API, khóa view
                dispatch(atcCallLockElementsChat(true));

                setLoading((prevState) => ({ ...prevState, submitQuestion: true }));
                handleScrollBottom();

                // Check Question type
                let paramsAnswer;
                switch (QuestionTypeCurrent) {
                    case "multiple_choice":
                        paramsAnswer = SubType === "select_many" ? optionsData?.SelectMany : optionsData?.SelectOne;
                        break;
                    case "free_text":
                        paramsAnswer = AnswerInput;
                        break;
                    default:
                        paramsAnswer = "";
                        break;
                }

                // Params
                const params = {
                    "CaseId": id,
                    "QuestionId": questionId,
                    "QuestionType": QuestionTypeCurrent,
                    "Answer": paramsAnswer
                };

                // Push Data to reducer
                await pushDataToReducerForUser(paramsAnswer, QuestionTypeCurrent);

                // Gọi API
                const res = await apiCaller(`/api/learn/question/submit/`, "POST", params);

                if (res?.status !== 200) {
                    dispatch({ type: questionConstants.QUESTION_FAILURE });
                    resetLoadingAndMessage();
                    dispatch(atcCallLockElementsChat(false)); // Mở khóa view nếu có lỗi
                    reject(new Error("Failed to submit question"));
                    return;
                }

                const data = res?.data;
                const QuestionResponse = data?.QuestionResponse;

                // Reset Loading and Message
                resetLoadingAndMessage();

                // Condition to set next question
                if (QuestionResponse !== null) {
                    setNextQuestion(true);
                }

                // Update Reducer
                dispatch({
                    type: questionConstants.QUESTION_SUBMIT,
                    payload: data,
                });

                // Sau khi xử lý API, mở khóa view
                dispatch(atcCallLockElementsChat(false));

                // Handle next question if needed
                handleNextQuestionIfNeeded(QuestionResponse, QuestionTypeCurrent);

                // Clear selected data for multiple choice
                if (QuestionTypeCurrent === "multiple_choice") {
                    clearMultipleChoiceData(optionsData, SubType, setOptionsData);
                }

                // Dung Request timeout va xoa data stream (Quan trong - de hien thi du lieu)
                setStream(null);
                clearInterval(intervalIDRef.current);
                intervalIDRef.current = null;

                // Reset Data fill input
                setvalueOptions(null);

                // Focus lại vào textarea sau khi xử lý API
                if (textareaRef.current) {
                    textareaRef.current.focus();
                }

                // Handle stream data
                handleStreamData(Promise.resolve(data));

                // Resolve kết quả
                resolve(data);
            } catch (error) {
                // Mở khóa view trong trường hợp lỗi
                dispatch(atcCallLockElementsChat(false));
                resetLoadingAndMessage();
                reject(error);
            }
        });
    };

    //Call api Next Question (Khi Click Got It)
    const handleNextQuestion = () => {
        dispatch({ type: questionConstants.QUESTION_REQUEST, isLoading: true });

        // Trước khi gọi API, khóa view
        dispatch(atcCallLockElementsChat(true));
        apiCaller(`/api/learn/question/next/${id}/?task=${isCurrentTask}`, "GET", null).then((res) => {
            if (res.status === 200) {
                const data = res?.data;
                const Type = data?.Question?.Type;
                const qsResponse = data?.Question;
                const resQuestionNull = data?.Question === null;
                // const TaskToResume = data?.TaskToResume;
                handleUpdateRecord(qsResponse, Type, 'Instructor');

                //Set new Question Id
                setQuestionId(data?.Question?.Id);

                dispatch({
                    type: questionConstants.QUESTION_SUCCESS,
                    payload: data,
                    isLoading: false
                });

                // Neu Question Null thi kiem Tra Instruction co khong ?
                // => Neu Co Instruction Thi Show Toast tuong ung vs Task hien tai
                if (resQuestionNull && isCurrentTask) {
                    handleFetchInstruction(id, isCurrentTask);
                }

                //Hidden Loading
                setNextQuestion(false); //Da Nhan nut Next Question

                // Sau khi xử lý API, mở khóa view
                dispatch(atcCallLockElementsChat(false));

                // Focus lại vào textarea sau khi xử lý API
                if (textareaRef.current) {
                    textareaRef.current.focus();
                }
            } else {
                //Hidden Loading
                dispatch({ type: questionConstants.QUESTION_FAILURE, error: "error", isLoading: false });

                // Sau khi xử lý API, mở khóa view
                dispatch(atcCallLockElementsChat(false));
            }
        });
    };

    // Hiển thị modal khi là Safari trên iOS hoặc macOS chỉ một lần
    useEffect(() => {
        const isIOSOrMacOSSafari = () => {
            const userAgent = navigator.userAgent;
        
            // Kiểm tra iOS
            const isIOS = /iPhone|iPad|iPod/.test(userAgent);
        
            // Kiểm tra Safari trên macOS
            const isSafariOnMac = /Macintosh/.test(userAgent) && /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
        
            return isIOS || isSafariOnMac;
        };

        if (isIOSOrMacOSSafari() && isFreeText && !statusMessageChatVoice && FirstTimePoint) {
            setModalMessageIOS(true);
            dispatch(setTypeMicrophoneInstructor(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device, deviceType, isFreeText, statusMessageChatVoice, FirstTimePoint]);

    //Characters
    const Characters = caseResponse?.Characters;
    const Instructor = Characters?.Instructor;
    const InstructorCharacter = Instructor?.Character;
    const preceptorNameText = Instructor?.DisplayName;
    const PreceptorAvatar = InstructorCharacter?.StandardSquarePhoto || imageInstructor;
    return (
        <React.Fragment>
            <MicrophoneMessage
                title={`Notice About Voice Feature`}
                content={`This case supports Voice-based chat, but the feature is currently unavailable on iOS devices. You can still interact using Text. To explore the Voice feature, please use a desktop, laptop, or Android device. We apologize for the inconvenience and are actively working on a solution. All other features are fully accessible!`}
                isOpen={modalMessageIOS}
                icon={IconNotSupport}
                onClose={() => setModalMessageIOS(false)} // Đóng modal khi người dùng tắt
            />
            <div className={`frameChat__fullBox interact_panel position-relative`} data-type="instructor-free-text">
                {showUserActive && <UserActive userNameActive={preceptorNameText} RoleDefault={`Preceptor`} image={PreceptorAvatar} />}
                <ScrollableMessages
                    {...props}
                    messagesEndRef={messagesEndRef}
                    isLoading={isLoading}
                    contentStream={contentStream}
                    heightFooterChat={heightFooterChat}
                    nextQuestion={nextQuestion}
                />
                <QuestionContainer
                    {...props}
                    divRef={divRef}
                    nextQuestion={nextQuestion}
                    TaskToResume={TaskToResume}
                    SubType={SubType}
                    Type={QuestionTypeCurrent}
                    isLoading={isLoading}
                    QuestionResponse={QuestionResponse}
                    optionsData={optionsData}
                    handleSelectMany={handleSelectMany}
                    handleSelectOne={handleSelectOne}
                    handleSubmitQuestion={handleSubmitQuestion}
                    valueOptions={valueOptions}
                    isMessageLoader={isMessageLoader}
                    handleNextQuestion={handleNextQuestion}
                    textareaRef={textareaRef}
                />
            </div>
        </React.Fragment>
    );
};

export default Framechat;