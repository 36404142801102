export const chatConstants = {
    CHAT_ADD_RECORD_CHANEL_REQUEST: 'CHAT_ADD_RECORD_CHANEL_REQUEST',
    CHAT_ADD_RECORD_CHANEL_SUCCESS: 'CHAT_ADD_RECORD_CHANEL_SUCCESS',
    CHAT_ADD_RECORD_CHANEL_FAILURE: 'CHAT_ADD_RECORD_CHANEL_FAILURE',

    LOAD_RECORD_INSTRUCTOR_REQUEST: 'LOAD_RECORD_INSTRUCTOR_REQUEST',
    LOAD_RECORD_INSTRUCTOR_SUCCESS: 'LOAD_RECORD_INSTRUCTOR_SUCCESS',
    LOAD_RECORD_INSTRUCTOR_FAILURE: 'LOAD_RECORD_INSTRUCTOR_FAILURE',

    LOAD_RECORD_PATIENT_REQUEST: 'LOAD_RECORD_PATIENT_REQUEST',
    LOAD_RECORD_PATIENT_SUCCESS: 'LOAD_RECORD_PATIENT_SUCCESS',
    LOAD_RECORD_PATIENT_FAILURE: 'LOAD_RECORD_PATIENT_FAILURE',

    CHAT_FREE_TEXT_RECORD_REQUEST: 'CHAT_FREE_TEXT_RECORD_REQUEST',
    CHAT_FREE_TEXT_RECORD_SUCCESS: 'CHAT_FREE_TEXT_RECORD_SUCCESS',
    CHAT_FREE_TEXT_RECORD_FAILURE: 'CHAT_FREE_TEXT_RECORD_FAILURE',

    IS_RENDER_RECORD_PATIENT_SUCCESS: 'IS_RENDER_RECORD_PATIENT_SUCCESS', // Check Xem load chua
    CHECK_HAS_CHAT_LOCK_ELEMENT: 'CHECK_HAS_CHAT_LOCK_ELEMENT', // Check Xem có đang chat

    //Mode is EndChat (Chat - Patient) 
    UPDATE_CHAT_LOCK_SUCCESS: 'UPDATE_CHAT_LOCK_SUCCESS'
};