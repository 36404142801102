import { useEffect, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { Iconsax } from "@components-common"; // Import Iconsax

const Container = styled.div`
  position: absolute;
  bottom: 18px;
  right: 18px;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownToggle = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Giúp căn chỉnh biểu tượng */
  padding: 4px 8px;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
  background: #8d8d8d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 75px;
  position: relative;
  height: 32px;
`;

const DropdownMenu = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 8px;
  width: 186px;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background: #fff;
  box-shadow: 0px 5px 32px -4px rgba(24, 39, 75, 0.06),
              0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  color: #1d2939;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  z-index: 1000;
`;

const DropdownItem = styled.button`
  display: flex;
  padding: 10px 10px 10px 8px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  &:hover {
    background: #f7f8f9;
    border-radius: 8px;
  }
`;

const TooltipStyled = styled(ReactTooltip)`
  opacity: ${({ open }) => (open ? "0 !important" : "1 !important")};
  visibility: ${({ open }) => (open ? "hidden !important" : "visible !important")};
  transition: opacity 0.2s ease-in-out;
  border-radius: 8px !important;
  background: #121926 !important;
  color: white !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  &:after {
    background-color: #121926 !important;
  }
`;

const speedOptions = [0.5, 0.75, 1, 1.2, 1.5, 2.0];

export default function SpeedSelector({ selectedSpeed, setSelectedSpeed }) {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        ReactTooltip.rebuild();
        ReactTooltip.show(document.getElementById("dropdown-toggle"));
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Container ref={dropdownRef}>
            <DropdownContainer>
                <DropdownToggle
                    id="dropdown-toggle"
                    data-tip="Voice Speed"
                    data-for="voice-speed-tooltip"
                    onClick={() => setOpen((prev) => !prev)}
                >
                    {selectedSpeed}x
                    <Iconsax
                        iconName={open ? "chevron-up" : "chevron-down"}
                        fill="#fff"
                        size={20}
                    />
                </DropdownToggle>
                <TooltipStyled id="voice-speed-tooltip" place="top" effect="solid" offset={{ left: 18 }} open={open} />
                <DropdownMenu open={open}>
                    {speedOptions.map((speed) => (
                        <DropdownItem
                            key={speed}
                            onClick={() => {
                                setSelectedSpeed(speed);
                                setOpen(false);
                            }}
                        >
                            {speed}x
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </DropdownContainer>
        </Container>
    );
}
